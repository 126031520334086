import React from "react"
import "twin.macro"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import { TextLink } from "../components/Trypography"

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Page not found">
      <PageTitle title="Page not found" />
      <p>Sorry, we couldn’t find what you were looking for.</p>
      <TextLink to="/" tw="">Go home page</TextLink>.
    </Layout>
  )
}

export default NotFoundPage
